/* 
 * Tyrecom
 */

'use strict';
import $ from 'jquery';

$.fn.collapseGroup = function () {
    const EVENTKEY = '.tc.collapsegroup';
    const Event = {
        COLLAPSE_SHOWN: 'shown.bs.collapse',
        COLLAPSE_SHOW: 'show.bs.collapse',
        COLLAPSE_HIDE: 'hide.bs.collapse',
        CLICK: 'click',
        MASK_SHOW: 'show' + EVENTKEY,
        MASK_HIDE: 'hide' + EVENTKEY
    };
    const Action = {
        HIDE: 'hide'
    };
    const ClassName = {
        BLOCK: 'collapse-group',
        MASK: 'collapse-group-mask',
        NO_SCROLL: 'no-scroll',
        ACTIVE: 'active'
    };
    const Selector = {
        BLOCK: '.collapse-group',
        MASK: '.collapse-group-mask',
        BODY: 'body',
        ACTIVE: '.active',
        FOCUS: '[data-expand-focus="true"]'
    };
    class CollapseGroup {
        constructor(parent) {
            this._parent = parent;
            this._items = $(Selector.BLOCK, this._parent);
            this._mask = $(Selector.MASK);
            this._visible = null;

            // Listeners
            this._items.on(Event.COLLAPSE_SHOW, (event) => {
                if (this._mask.not(Selector.ACTIVE)) {
                    this.showMask();
                }
                let target = $(event.target);
                if (this._items.has(target)) {
                    this._visible = target;
                }
            });
            this._items.on(Event.COLLAPSE_HIDE, (event) => {
                let target = $(event.target);
                if (this._visible && this._visible.is(target)) {
                    this.hideMask();
                }
            });
            this._items.on(Event.COLLAPSE_SHOWN, (event) => {
                let target = $(event.target);
                let focusElement = $(Selector.FOCUS, target);
                if (focusElement.length > 0) {
                    focusElement[0].focus();
                }
            });
            this._mask.on(Event.CLICK, (event) => {
                this._items.collapse(Action.HIDE);
            });
        }
        _triggerShowEvent() {
            let showEvent = $.Event(Event.MASK_SHOW);
            this._parent.trigger(showEvent);
            return showEvent;
        }
        _triggerHideEvent() {
            let hideEvent = $.Event(Event.MASK_HIDE);
            this._parent.trigger(hideEvent);
            return hideEvent;
        }
        showMask() {
            let showEvent = this._triggerShowEvent();
            if (showEvent.isDefaultPrevented()) {
                return;
            }
            this._mask.addClass(ClassName.ACTIVE);
            this._mask.fadeIn();
            $(Selector.BODY).addClass(ClassName.NO_SCROLL);
        }
        hideMask() {
            let hideEvent = this._triggerHideEvent();
            if (hideEvent.isDefaultPrevented()) {
                return;
            }
            this._mask.removeClass(ClassName.ACTIVE);
            this._mask.fadeOut();
            this._visible = null;
            $(Selector.BODY).removeClass(ClassName.NO_SCROLL);
        }
    }
    for (let element of this) {
        new CollapseGroup($(element));
    }
    return this;
};
