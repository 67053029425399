/* 
 * Tyrecom
 */

'use strict';

import $ from 'jquery';

$.fn.topMenu = function () {
    const EVENTKEY = '.tc.topmenu';
    const Event = {
        CLICK: 'click',
        SHOW_MENU: 'show' + EVENTKEY,
        HIDE_MENU: 'hide' + EVENTKEY
    };
    const ClassName = {
        SHOW_ICON: 'fas fa-caret-down',
        HIDE_ICON: 'fas fa-caret-up'
    };
    const Selector = {
        PARENT: '.parent',
        LINK: 'a',
        SUBMENU: 'ul',
        LIST_ITEM: 'li',
        SHOW_ICON: '.fas.fa-caret-down',
        HIDE_ICON: '.fas.fa-caret-up'
    };
    const Element = {
        SHOW_ICON: '<i class="' + ClassName.SHOW_ICON + '"></i>',
        HIDE_ICON: '<i class="' + ClassName.HIDE_ICON + '"></i>'
    };
    
    class TopMenu {
        constructor(menu) {
            this._menu = menu;
            this._showIcon = $(Element.SHOW_ICON);
            this._hideIcon = $(Element.HIDE_ICON);
            this._parents = $(Selector.PARENT, this._menu);
            
            this._showIcon.on(Event.CLICK, (event) => {
                let menuItem = $(event.target).closest(Selector.LIST_ITEM);
                this.showMenuItem(menuItem);
            });
            this._hideIcon.on(Event.CLICK, (event) => {
                let menuItem = $(event.target).closest(Selector.LIST_ITEM);
                this.hideMenuItem(menuItem);
            });
            this._decorate();
        }
        _decorate() {
            this._parents.children(Selector.LINK)
                    .after(this._showIcon)
                    .after(this._hideIcon);
        }
        _triggerShowEvent(menuItem) {
            let showEvent = $.Event(Event.SHOW_MENU);
            menuItem.trigger(showEvent);
            return showEvent;
        }
        _triggerHideEvent(menuItem) {
            let hideEvent = $.Event(Event.HIDE_MENU);
            menuItem.trigger(hideEvent);
            return hideEvent;
        }
        showMenuItem(menuItem) {
            let showEvent = this._triggerShowEvent(menuItem);
            if (showEvent.isDefaultPrevented()) {
                return;
            }
            $(Selector.SHOW_ICON, menuItem).hide();
            $(Selector.HIDE_ICON, menuItem).show();
            $(Selector.SUBMENU, menuItem).slideDown();
        }
        hideMenuItem(menuItem) {
            let hideEvent = this._triggerHideEvent(menuItem);
            if (hideEvent.isDefaultPrevented()) {
                return;
            }
            $(Selector.HIDE_ICON, menuItem).hide();
            $(Selector.SHOW_ICON, menuItem).show();
            $(Selector.SUBMENU, menuItem).slideUp();
        }
    }
    for (let element of this) {
        new TopMenu($(element));
    }
    return this;
};

//class TopMenu {
//    constructor($menu) {
//        this.menu = $menu;
//        this.showIcon = $('<i class="fas fa-caret-down"></i>');
//        this.hideIcon = $('<i class="fas fa-caret-up"></i>');
//        this.parents = $('.parent', this.menu);
//        
//        this.register();
//        this.decorate();
//    }
//    
//    register() {
//        this.showIcon.on('click', (event) => {
//            let toggle = $(event.currentTarget);
//            toggle.closest('li').children('ul').slideDown();            
//            toggle.closest('li').children('i').not(toggle).show();
//            toggle.hide();            
//        });
//        this.hideIcon.on('click', (event) => {
//            let toggle = $(event.currentTarget);
//            toggle.closest('li').children('ul').slideUp();            
//            toggle.closest('li').children('i').not(toggle).show();
//            toggle.hide();
//        });
//    }
//
//    decorate() {
//        this.parents.children('a').after(this.hideIcon);
//        this.parents.children('a').after(this.showIcon);
//    }
//}
//
//export default TopMenu;
