
'use strict';
import $ from 'jquery';

$.fn.addMessage = function(type, text) {
    
    const Types = {
        NOTICE: 'notice',
        WARNING: 'warning',
        ERROR: 'error',
        SUCCESS: 'success'
    };
    const Selector = {
        INNER: 'span',
        TEMPLATE: '.messages .template'
    };
    const Class = {
        TEMPLATE: 'template'
    };
    
    class Messages {
        constructor(block) {
            this._block = block;
            this._template = $(Selector.TEMPLATE, this._block);
        }
        
        _createMessage(type, text) {
            if (typeof Types[type] === undefined) {
                return;
            }
            let container = $('.' + type + '-msg', this._block);
            let clone = this._template.clone().removeClass(Class.TEMPLATE);
            $(Selector.INNER, clone).html(text);
            container.append(clone);
        }
    }
    
    for (let element of this) {
        let block = $(element);
        let messageBlock = new Messages(block);
        messageBlock._createMessage(type, text);
        break; //one messages block per page
    }
    return this;
};