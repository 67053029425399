/* 
 * Tyrecom
 */

'use strict';
import $ from 'jquery';

$.fn.topSearch = function () {

    const Selector = {
        INPUT: 'input[name="q"]',
        RESULTS: '.search-autocomplete',
        LIST: 'ul',
        ITEM: 'li'
    };
    const Event = {
        KEYUP: 'keyup',
        CHANGE: 'change',
        INPUT: 'input'
    };
    const Data = {
        URL: 'url',
        MINIMUM: 'minimum',
        DEBOUNCE: 'debounce'
    };

    class TopSearch {
        constructor(form) {
            this._form = form;
            this._input = $(Selector.INPUT, this._form);
            this._results = $(Selector.RESULTS, this._form);
            this._query = '';

            this._settings = {
                url: this._form.data(Data.URL),
                minimum: this._form.data(Data.MINIMUM),
                debounce: this._form.data(Data.DEBOUNCE)
            };

            this._watch = [Event.KEYUP, Event.CHANGE, Event.INPUT].join(', ');
            this._input.on(
                    this._watch,
                    this.debounce(this._settings.debounce, () => {
                        this.handleQuery();
                    }));

            this.handleQuery();
        }

        debounce(delay, callback) {
            let timeout;
            return (...args) => {
                if (timeout) {
                    clearTimeout(timeout);
                }
                timeout = setTimeout(() => {
                    callback(...args);
                    timeout = null;
                }, delay);
            };
        }

        handleQuery() {
            this._query = this._input.val();
            if (this.validateQuery()) {
                this.ajaxSearch();
            } else {
                $(Selector.LIST, this._results).slideUp(null, () => {
                    this._results.html('');
                });
            }
        }

        handleResponse(response) {
            let previous = $(Selector.LIST, this._results);
            let current = $(response).hide();

            if (current.children(Selector.ITEM).length > 0) {
                this._results.html(current);
                if (previous.children(Selector.ITEM).length > 0) {
                    previous.fadeOut();
                    current.fadeIn();
                } else {
                    current.slideDown();
                }
            } else {
                previous.slideUp();
            }
        }

        ajaxSearch() {
            let url = this._settings.url;
            $.ajax(url, {
                data: {q: this._query},
                success: ((response) => {
                    this.handleResponse(response);
                })
            });
        }

        validateQuery() {
            if (this._query.length <= this._settings.minimum) {
                return false;
            }
            return true;
        }
    }

    for (let element of this) {
        let form = $(element);
        new TopSearch(form);
    }
    return this;
};