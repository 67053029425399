/* 
 * Tyrecom
 */

'use strict';
import $ from 'jquery';
import './collapsecheckbox.js';

$.fn.checkout = function () {
    const Selector = {
        // AjaxCheckout
        CHECKOUT_METHOD: '#checkout_create_account',
        BILLING_POSTCODE: '#billing\\:postcode',
        BILLING_COUNTRY: '#billing\\:country_id',
        SHIPPING_POSTCODE: '#shipping\\:postcode',
        SHIPPING_COUNTRY: '#shipping\\:country_id',
        PAYMENT_METHODS: '#payment_method input[name="payment[method]"]',
        SHIPPING_METHODS: '#shipping_method input[name="shipping_method"]',
        CHECKOUT_SECTION: '[data-role="checkout-section"]',
        CHECKOUT_BUTTON_SUBMIT: '#checkout_onepage_submit',
        CHECKOUT_BUTTON_WAIT: '#checkout_onepage_working',
        REVIEW_SECTION: '#review_table',
        SHIPPING_METHOD_SECTION: '#shipping_method',
        PAYMENT_METHOD_SECTION: '#payment_method',
        BILLING_USE_FOR_SHIPPING: '#billing\\:use_for_shipping',
        SHIPPING_ADDRESS_INNER: '#shipping_address_inner',
        // Shared
        INPUTS: 'input, textarea, select, button',
        VISIBLE: ':visible',
        DISABLED: ':disabled',
        // Checkout
        COLLAPSE: '.collapse',
        CHECKOUT_WRAPPER: '#checkout_wrapper',
        CHECKOUT_MASK: '.checkout-mask',
        ACCOUNT: '#checkout_account',
        LOGIN: '#checkout_login',
        LOGIN_CONTINUE: '#login_continue',
        REGISTER: '#checkout_register',
        REGISTER_EMAIL: '#register_email',
        REGISTER_CONTINUE: '#register_continue',
        REGISTER_PASSWORD: '#checkout_login_password_container'
    };
    const Event = {
        CLICK: 'click',
        CHANGE: 'change',
        SUBMIT: 'submit',
        COLLAPSE_SHOW: 'show.bs.collapse',
        COLLAPSE_HIDE: 'hide.bs.collapse',
        COLLAPSE_SHOWN: 'shown.bs.collapse',
        COLLAPSE_HIDDEN: 'hidden.bs.collapse',
        CHECKOUT_LOCK: 'lock.tc.checkout',
        CHECKOUT_UNLOCK: 'unlock.tc.checkout',
        AJAX_START: 'start.tc.ajaxcheckout',
        AJAX_END: 'end.tc.ajaxcheckout'
    };
    const Data = {
        AJAX_URL: 'data-ajax',
        INIT: 'data-init',
        DISABLED: 'disabled'
    };

    class AjaxCheckout {
        constructor(checkout) {
            this._checkout = checkout;
            this._count = 0;
            this._url = this._checkout.attr(Data.AJAX_URL);
            this._inProgress = null;
            this._changed = null;
            this._staticFields = $([
                Selector.INPUTS,
//                Selector.CHECKOUT_METHOD,
//                Selector.BILLING_POSTCODE,
//                Selector.BILLING_COUNTRY,
//                Selector.SHIPPING_POSTCODE,
//                Selector.SHIPPING_COUNTRY
            ].join(', '), this._checkout);
            this._shipping = $(Selector.SHIPPING_ADDRESS_INNER);
            this._reviewSection = $(Selector.REVIEW_SECTION, this._checkout);
            this._paymentMethodSection = $(Selector.PAYMENT_METHOD_SECTION, this._checkout);
            this._shippingMethodSection = $(Selector.SHIPPING_METHOD_SECTION, this._checkout);

            this._initFields(this._staticFields);
            this._initCheckbox();

            this._start();
        }

        _initFields(fields, eventType) {
            if (!eventType) {
                eventType = Event.CHANGE;
            }
            let init = fields.filter((i, field) => {
                if (!$(field).prop('init')) {
                    return true;
                }
            });
            init.on(eventType, (event) => {
                this._changed = $(event.currentTarget).closest(Selector.CHECKOUT_SECTION).attr('id');
                this._start();
            });
            init.prop('init', true);
        }

        _initCheckbox() {
            if ($(Selector.BILLING_USE_FOR_SHIPPING).is(':checked')) {
                this._shipping.addClass('fade');
                this._shipping.toggleInput('disable');
            }
            $(Selector.BILLING_USE_FOR_SHIPPING).on(Event.CHANGE, (event) => {
                if ($(Selector.BILLING_USE_FOR_SHIPPING).is(':checked')) {
                    this._shipping.addClass('fade');
                    this._shipping.toggleInput('disable');
                } else {
                    this._shipping.removeClass('fade');
                    this._shipping.toggleInput('enable');
                }
            });
        }

        _isMethodSet(formData) {
            for (let item of formData) {
                if (item.name === 'method' && item.value) {
                    return true;
                }
            }
        }

        _ajaxRequest() {
            this._abortPrevious();
            this._count++;
            let formData = this._checkout.serializeArray();
            formData.push({name: 'count', value: this._getRequestId()});
            if (!this._isMethodSet(formData)) {
                formData.push({name: 'method', value: 'guest'});
            }
            if (this._changed) {
                formData.push({name: 'changed', value: this._changed});
            }
            this._inProgress = $.ajax(this._url, {method: 'POST', data: $.param(formData)});
            return this._inProgress;
        }

        _abortPrevious() {
            if (this._inProgress) {
                this._inProgress.abort();
            }
        }

        _getRequestId() {
            return this._checkout.attr('id') + '_' + this._count;
        }

        _getDynamicFields() {
            return $([
                Selector.PAYMENT_METHODS,
                Selector.SHIPPING_METHODS
            ].join(', '), this._checkout);
        }

        _start() {
            this._checkout.trigger(Event.AJAX_START);
            $(Selector.CHECKOUT_BUTTON_SUBMIT).hide();
            $(Selector.CHECKOUT_BUTTON_WAIT).show();
            this._ajaxRequest().done((response) => {
                this._inProgress = null;
                this._update(response);
                this._getDynamicFields().collapseCheckbox();
                this._initFields(this._getDynamicFields());
                this._stop();
            });
        }

        _stop() {
            this._checkout.trigger(Event.AJAX_END);
            $(Selector.CHECKOUT_BUTTON_WAIT).hide();
            $(Selector.CHECKOUT_BUTTON_SUBMIT).show();
        }

        _update(response) {
            if (this._getRequestId() === response.responseId) {
                if (response.html.review) {
                    this._reviewSection.html(response.html.review);
                }
                if (response.html.paymentMethod) {
                    this._paymentMethodSection.html(response.html.paymentMethod);
                }
                if (response.html.shippingMethod) {
                    this._shippingMethodSection.html(response.html.shippingMethod);
                }
            }
        }
    }

    class Checkout {
        constructor(checkout) {
            this._checkout = checkout;
            this._account = $(Selector.ACCOUNT, this._checkout);
            this._inner = $(Selector.CHECKOUT_WRAPPER, this._checkout);
            this._lockCheckout();
            this._registerFunctions();
        }

        _lockCheckout() {
            if ($(Selector.CHECKOUT_MASK).length > 0) {
                this._checkout.trigger(Event.CHECKOUT_LOCK);
                $(Selector.CHECKOUT_MASK, this._checkout).fadeIn();
                $(Selector.INPUTS, this._inner).not(Selector.BILLING_USE_FOR_SHIPPING).prop(Data.DISABLED, true);
                $(Selector.INPUTS, this._account).prop(Data.DISABLED, true);
            }
        }

        _unlockCheckout() {
            this._checkout.trigger(Event.CHECKOUT_UNLOCK);
            $(Selector.CHECKOUT_MASK, this._checkout).fadeOut(() => {
                $(Selector.INPUTS, this._inner).filter(Selector.VISIBLE).prop(Data.DISABLED, false);
                if ($(Selector.BILLING_USE_FOR_SHIPPING).is(':checked')) {
                    $(Selector.SHIPPING_ADDRESS_INNER).addClass('fade');
                    $(Selector.SHIPPING_ADDRESS_INNER).toggleInput('disable');
                }
            });
        }

        _registerFunctions() {
            let subforms = $([Selector.LOGIN, Selector.REGISTER, Selector.REGISTER_PASSWORD, Selector.SHIPPING_ADDRESS_INNER].join(', '), this._checkout);
            subforms.on(Event.COLLAPSE_HIDE, (event) => {
                let area = $(event.target);
                if (area.is(Selector.LOGIN) || area.is(Selector.REGISTER)) {
                    this._lockCheckout();
                }
            });
            $(Selector.REGISTER_CONTINUE, this._checkout).on(Event.CLICK, (event) => {
                let input = $(Selector.REGISTER_EMAIL);
                if (input.is(':valid')) {
                    this._unlockCheckout();
                } else {
                    input.trigger('invalid');
                }
            });
            this._checkout.on(Event.AJAX_END, (event) => {
                if ($(Selector.CHECKOUT_MASK).is(Selector.VISIBLE)) {
                    $(Selector.INPUTS, this._inner).prop(Data.DISABLED, true);
                }
            });

            this._checkout.on(Event.SUBMIT, (event) => {
                if (!this._canSubmit()) {
                    event.preventDefault();
                }
            });
        }

        _canSubmit() {
            let locked = $(Selector.CHECKOUT_MASK).is(Selector.VISIBLE);
            let submit = $('button[type="submit"]').filter(Selector.VISIBLE).not(Selector.DISABLED);
            if (!locked || submit.length > 0) {
                return true;
            }
            return false;
        }
    }

    for (let element of this) {
        let checkout = $(element);
        new Checkout(checkout);
        new AjaxCheckout(checkout);
        break; // Only one checkout per page
    }
    return this;
};
