/* 
 * Tyrecom
 */

'use strict';

import $ from 'jquery';

$.fn.inputLimit = function() {
    const Data = {
        MAX_LENGTH: 'maxlength'
    };
    const Event = {
        INPUT: 'input'
    };
    const Selector = {
        INPUT: 'input, textarea',
        COUNTER: '.counter'
    };
    
    class InputLimit {
        constructor(container) {
            this._input = $(Selector.INPUT, container);
            this._counter = $(Selector.COUNTER, container);
            this._maxLength = this._input.prop(Data.MAX_LENGTH);
            
            this._input.on(Event.INPUT, (event) => {
                this._updateCounter();
            });
            this._updateCounter();
        }
        
        _updateCounter() {
            let length = this._input.val().length;
            this._counter.html(length + '/' + this._maxLength);
        }
    }    
    for (let element of this) {
        new InputLimit($(element));
    }
    return this;
};
