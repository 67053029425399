/* 
 * Tyrecom
 */

'use strict';

import $ from 'jquery';

$.fn.qtySwitcher = function () {
    const Event = {
        CLICK: 'click',
        CHANGE: 'change'
    };
    const Selector = {
        INPUT: '[data-role="qty-input"]',
        INCREASE: '[data-role="qty-increase"]',
        DECREASE: '[data-role="qty-decrease"]'
    };
    const Data = {
        MINIMUM: 'data-min',
        MAXIMUM: 'data-max',
        STEP: 'data-step',
        DECIMAL: 'data-decimal'
    };
    class QtySwitcher {
        constructor(container) {
            this._container = container;
            this._input = $(Selector.INPUT, this._container);
            this._increase = $(Selector.INCREASE, this._container);
            this._decrease = $(Selector.DECREASE, this._container);
            this._min = parseFloat(this._container.attr(Data.MINIMUM));
            this._max = parseFloat(this._container.attr(Data.MAXIMUM));
            this._step = parseFloat(this._container.attr(Data.STEP));
            this._decimal = this._container.data(Data.DECIMAL);

            this._increase.on(Event.CLICK, () => {
                this._increaseQty();
            });
            this._decrease.on(Event.CLICK, () => {
                this._decreaseQty();
            });
            this._input.on(Event.CHANGE, () => {
                this._setQty();
            });
        }
        _getQty() {
            return this._filterInput(this._input.val());
        }
        _increaseQty() {
            let qty = this._getQty();
            qty += this._step;
            this._updateQty(qty);
        }
        _decreaseQty() {
            let qty = this._filterInput(this._input.val());
            qty -= this._step;
            this._updateQty(qty);
        }
        _setQty() {
            let qty = this._filterInput(this._input.val());
            this._updateQty(qty);
        }
        _filterInput(inputVal) {
            let qty;
            if (this._decimal) {
                qty = parseFloat(inputVal);
            } else {
                qty = parseInt(inputVal);
            }
            return qty;
        }
        _filterQty(qty) {
            if (isNaN(qty) || qty < this._min) {
                return this._min;
            }
            if (qty > this._max) {
                return this._max;
            }
            return qty;
        }
        _updateQty(qty) {
            this._input.val(this._filterQty(qty));
        }
    }
    for (let element of this) {
        new QtySwitcher($(element));
    }
    return this;
};
