/* 
 * Tyrecom
 */

'use strict';
import $ from 'jquery';
import Util from 'bootstrap/js/dist/util';

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'toggleInput';
const DATA_KEY = 'tc.toggleInput';
const JQUERY_NO_CONFLICT = $.fn[NAME];

const Data = {
    DISABLE_HIDDEN: 'disable-hidden'
};

const Event = {
    SHOW: 'show.bs.collapse',
    HIDE: 'hidden.bs.collapse',
    ENABLE: `enable.${DATA_KEY}`,
    DISABLE: `disable.${DATA_KEY}`
};

const Selector = {
    INPUTS: 'input, select, multiselect, textarea, button',
    CONTAINER: '.collapse',
    SHOW: '.collapse.show',
    VISIBLE: ':visible'
};

const Default = {};

const DefaultType = {};

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class ToggleInput {
    constructor(element, config) {
        this._element = element;
        this._config = this._getConfig(config);

        $(this._element).on(Event.SHOW, (event) => {
            if ($(event.target).is(this._element)) {
                this.enableVisible();
            }
        });

        $(this._element).on(Event.HIDE, (event) => {
            if ($(event.target).is(this._element)) {
                this.disable();
            }
        });
    }

    // Public

    enableVisible() {
        let area = $(this._element);
        do {
            $(Selector.INPUTS, area).filter((n, input) => {
                let parent = $(input).closest(Selector.CONTAINER);
                if (parent.is(area)) {
                    return true;
                }
            }).prop('disabled', false);
            area = area.find(Selector.SHOW);
        } while (area.length > 0);
    }
    
    enable() {
        $(Selector.INPUTS, this._element).prop('disabled', false);
    }

    disable() {
        $(Selector.INPUTS, this._element).prop('disabled', true);
    }

    dispose() {
        $.removeData(this._element, DATA_KEY);
        this._config = null;
        this._element = null;
    }

    // Private

    _getConfig(config) {
        config = {
            ...Default,
            ...config
        };
        Util.typeCheckConfig(NAME, config, DefaultType);
        return config;
    }

    static _jQueryInterface(config) {
        return this.each(function () {
            const $this = $(this);
            let data = $this.data(DATA_KEY);

            const _config = {
                ...Default,
                ...$this.data(),
                ...typeof config === 'object' && config ? config : {}
            };

            if (!data) {
                data = new ToggleInput(this, _config);
                $this.data(DATA_KEY, data);
            }

            if (typeof config === 'string') {
                if (typeof data[config] === 'undefined') {
                    throw new TypeError(`No method named "${config}"`);
                }
                data[config]();
            }
        });
    }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 */

$.fn[NAME] = ToggleInput._jQueryInterface;
$.fn[NAME].Constructor = ToggleInput;
$.fn[NAME].noConflict = () => {
    $.fn[NAME] = JQUERY_NO_CONFLICT;
    return ToggleInput._jQueryInterface;
};

export default ToggleInput