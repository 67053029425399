/* 
 * Tyrecom
 */

'use strict';

// Import JS Libraries
import $ from 'jquery';
import 'popper.js';

//import Bootstrap components
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';

// Import Tyrecom Components
import './components/checkout.js';
import './components/collapsecheckbox.js';
import './components/collapsegroup.js';
//import './components/collapseinput.js';
import './components/confirmpassword.js';
import './components/cookienotice.js';
import './components/formgroupmodern.js';
import './components/googlecaptcha.js';
import './components/inputlimit.js';
import './components/messages.js';
import './components/qtyswitcher.js';
import './components/redirect.js';
import './components/topmenu.js';
import './components/topsearch.js';
import './components/filtersearch.js';

// Import Tyrecom Components v2
import './components/toggleinput';

$(document).ready(() => {
    $('#header-collapse-group').collapseGroup();
    $('.collapse').toggleInput();
    $('#cookie-notice').cookieNotice();
    $('#nav').topMenu();
    $('[data-role="qty-switcher"]').qtySwitcher();
    $('.form-group-modern').formGroupModern();
    $('#onepage_checkout').checkout();
    $('.input-limit').inputLimit();
    $('[data-toggle="collapse-checkbox"]').collapseCheckbox();
//    $('[data-toggle="fade-checkbox"]').fadeCheckbox();
    $('#redirect').redirect();
    $('[data-role="confirm"][data-password]').confirmPassword();
    $('#topsearch').topSearch();
    $('.layered-navigation .filter').filterSearch();
    $([]).googleCaptcha();

    $('#estimate_method').on('change', () => {
        $('#estimate_method').submit();
    });

    let firstInvalid = true;

    $('*').on('invalid', (event) => {
        let target = $(event.target);
        target.closest('.form-group-modern').addClass('invalid');
        if (!firstInvalid || !target.is(':visible')) {
            return false;
        }
        firstInvalid = false;
        target[0].scrollIntoView();
        document.documentElement.scrollTop -= 200;
        setTimeout(() => {
            firstInvalid = true;
        }, 500);
    });

    // Tax Toggle
    
//    let checkIncludeTax = function () {
//        if ($('#includeTaxToggle').is(':checked')) {
//            $('#products-list .price-excluding-tax').removeClass('price-primary');
//            
//            $('#products-list .price-excluding-tax').removeClass('d-none');
//            $('#products-list .price-excluding-tax').addClass('d-block');
//            
//        } else {
//            $('#products-list .price-excluding-tax').addClass('price-primary');
//            
//            $('#products-list .price-including-tax').removeClass('d-none');
//            $('#products-list .price-excluding-tax').addClass('d-block');
//        }
//    };
//
//    $('#includeTaxToggle').on('ready change', () => {
//        checkIncludeTax();
//    });
//    checkIncludeTax();

});


