/* 
 * Tyrecom
 */

'use strict';
import $ from 'jquery';

$.fn.filterSearch = function () {
    const Data = {
        FILTER_SEARCH: 'filter-search',
        FILTER_ITEMS: 'filter-items',
        CODE: 'code',
        VALUE: 'value'
    };
    const Selector = {
        BUTTON: '.filter-btn',
        SEARCH: '[data-role="filter-search"]',
        ITEMS: '[data-role="filter-items"] li',
        INPUT: 'input'
    };
    const Event = {
        KEYUP: 'keyup',
        SHOW: 'shown.bs.collapse'
    };
    const Class = {
        SHOW: 'd-flex',
        HIDE: 'd-none'
    };
    
    class FilterSearch {
        constructor(container) {
            this._container = container;
            this._search = $(Selector.SEARCH, this._container);
            this._input = $(Selector.INPUT, this._search);
            this._items = $(Selector.ITEMS, this._container);
            this._code = this._search.data(Data.CODE);
            this._button = $(Selector.BUTTON, this._container);
            
            this._container.on(Event.SHOW, () => {
                this._input.focus();
            });
            
            this._input.on(Event.KEYUP, () => {
                let query = this._input.val();
                let show = this._items.filter((index, item) => {
                    let value = $(item).data(Data.VALUE).toString();
                    let regex = new RegExp('^.*' + query + '.*$', 'i');
                    let found = value.match(regex);
                    if (found) {
                        return true;
                    }
                    return false;
                });
                let hide = this._items.not(show);
                show.removeClass(Class.HIDE).addClass(Class.SHOW);
                hide.removeClass(Class.SHOW).addClass(Class.HIDE);                
            });
        }
    }
    
    for (let element of this) {
        new FilterSearch($(element));
    }
    return this;
};