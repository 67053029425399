/* 
 * Tyrecom
 */

'use strict';

import $ from 'jquery';

$.fn.cookieNotice = function() {
    const EVENTKEY = '.tc.cookienotice';
    const Event = {
        CLICK: 'click'
    };
    const Selector = {
        BUTTON_ACCEPT: 'button'
    };
    class CookieNotice {
        constructor(element) {
            this._elem = element;
            this._accept = $(Selector.BUTTON_ACCEPT, this._elem);
            
            this._accept.on(Event.CLICK, (event) => {
                this._setCookie();
                this._close();
            });
        }
        _setCookie() {
            document.cookie = "accept_cookies=1; path=/; max-age=" + (60 * 60 * 24 * 90); // 90 days
        }
        _close() {
            this._elem.slideUp();
        }
    }
    for (let element of this) {
        new CookieNotice($(element));
    }
    return this;
};
