/* 
 * Tyrecom
 */

'use strict';
import $ from 'jquery';

$.fn.redirect = function () {
    const Data = {
        DELAY: 'data-delay',
        HREF: 'href'
    };
    const Default = {
        DELAY: 5000
    };
    class Redirect {
        constructor(redirect) {
            let delay = redirect.attr(Data.DELAY) || Default.DELAY;
            setTimeout(() => {
                location.href = redirect.attr(Data.HREF);
            }, delay);
        }
    }
    for (let element of this) {
        new Redirect($(element));
        break; // Make sure only first redirect on the page is applied.
    }
    return this;
};