/* 
 * Tyrecom
 */

'use strict';
import $ from 'jquery';

$.fn.formGroupModern = function () {
    const Selector = {
        CHECK: '.fas.fa-check',
        INPUT: 'input, select',
        LABEL: 'label',
        VALID: ':valid'
    };
    const ClassName = {
        CHECK: 'fas fa-check mr-3 float-right',
        FOCUS: 'focus-on',
        VALID: 'valid',
        INVALID: 'invalid'
    };
    const Event = {
        CHANGE: 'change',
        FOCUS_IN: 'focusin',
        FOCUS_OUT: 'focusout'
    };
    const Element = {
        CHECK: '<i class="' + ClassName.CHECK + '" aria-hidden="true" style="display: none;"></i>'
    };

    class FormGroupModern {
        constructor(group) {
            this._group = group;
            this._check = $(Element.CHECK, group);
            this._label = $(Selector.LABEL, group);
            this._input = $(Selector.INPUT, group);

            this._label.append(this._check);

            this._group.on(Event.FOCUS_IN, (event) => {
                this._group.addClass(ClassName.FOCUS);
            });

            this._group.on(Event.FOCUS_OUT, (event) => {
                this._validate();
            });

            this._group.on(Event.CHANGE, (event) => {
                this._validate();
            });

            this._validate();
        }

        _validate() {
            if (this._input.val()) {
                this._group.addClass(ClassName.FOCUS);
                this._group.removeClass(ClassName.VALID);
                this._group.removeClass(ClassName.INVALID);
                if (this._input.is(Selector.VALID)) {
                    this._check.fadeIn();
                    this._group.addClass(ClassName.VALID);
                } else {
                    this._check.fadeOut();
                    this._group.addClass(ClassName.INVALID);
                }
            } else {
                this._check.fadeOut();
                this._group.removeClass(ClassName.FOCUS)
                        .removeClass(ClassName.VALID)
                        .removeClass(ClassName.INVALID);
            }
        }
    }

    for (let element of this) {
        new FormGroupModern($(element));
    }
    return this;
};
