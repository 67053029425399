/* 
 * Tyrecom
 */

'use strict';
import $ from 'jquery';

$.fn.googleCaptcha = function () {

    if (typeof window.renderCaptcha === 'undefined') {
        window.renderCaptcha = () => {
            for (let element of $('.g-recaptcha')) {
                let captcha = $(element);
                let siteKey = captcha.attr('site-key');
                grecaptcha.render(
                        element,
                        {
                            sitekey: siteKey
                        }
                );
            }
        };
    }

    return this;
};