/* 
 * Tyrecom
 */

'use strict';
import $ from 'jquery';

$.fn.collapseCheckbox = function () {
    const Data = {
        TOGGLE: 'toggle',
        TARGET: 'target',
        INVERT: 'invert',
        PARENT: 'name'
    };
    const Selector = {
        CHECKBOX: '[type="checkbox"]',
        RADIO: '[type="radio"]',
        CHECKED: ':checked',
        INPUTS: 'input, select, textarea'
    };
    const Event = {
        CHANGE: 'change',
        START: 'show.bs.collapse hide.bs.collapse',
        END: 'shown.bs.collapse hidden.bs.collapse'
    };

    class CollapseCheckbox {
        constructor(checkbox) {
            this._checkbox = checkbox;
            this._target = $(this._checkbox.data(Data.TARGET));

            if (this._checkbox.is(Selector.CHECKBOX)) {
                this._checkbox.on(Event.CHANGE, (event) => {
                    this._handleCheckbox(this._checkbox, this._target);
                });
            }
            if (this._checkbox.is(Selector.RADIO)) {
                this._checkbox.on(Event.CHANGE, (event) => {
                    this._handleRadio(this._checkbox);
                });
            }
            this._handleCheckbox(this._checkbox, this._target);
        }
        
        _showTarget(target) {
            target.collapse('show');
        }
        
        _hideTarget(target) {
            target.collapse('hide');
        }

        _handleCheckbox(checkbox, target) {
            if (!checkbox.prop('disabled')) {
                let invert = checkbox.data(Data.INVERT);
                if ((checkbox.is(Selector.CHECKED) ? !invert : invert)) {
                    this._showTarget(target);
                } else {
                    this._hideTarget(target);
                }
            }
        }

        _handleRadio(selected) {
            let all = $('[' + Data.PARENT + '="' + selected.attr(Data.PARENT) + '"]');
            for (let element of all) {
                let checkbox = $(element);
                let target = $(checkbox.data(Data.TARGET));
                this._handleCheckbox(checkbox, target);
            }
        }
    }
    for (let element of this) {
        new CollapseCheckbox($(element));
    }
    return this;
};
