/* 
 * Tyrecom
 */

'use strict';
import $ from 'jquery';

$.fn.confirmPassword = function () {

    const Event = {
        INPUT: 'input'
    };
    const Data = {
        PASSWORD: 'password'
    };
    const Selector = {
        GROUP: '.form-group'
    };
    const Class = {
        VALID: 'valid',
        INVALID: 'invalid'
    };

    class ConfirmPassword {
        constructor(confirm) {
            let password = $(confirm.data(Data.PASSWORD));
            if (password.length > 0) {
                confirm.add(password).on(Event.INPUT, (event) => {
                    let group = confirm.closest(Selector.GROUP);
                    if (password.val() === confirm.val()) {
                        group.removeClass(Class.INVALID);
                        group.addClass(Class.VALID);
                    } else {
                        group.removeClass(Class.VALID);
                        group.addClass(Class.INVALID);
                    }
                });
            }

        }
    }

    for (let element of this) {
        let confirm = $(element);
        new ConfirmPassword(confirm);
    }
    return this;
};

